import { defineStore } from 'pinia'
import { computed, readonly, type Ref, ref, watch } from 'vue'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import type { PartialWithRequiredFields } from '@/models/utils'
import type { Keim } from '@/stores/keime/Keim'
import type { UpdateKeimRequest } from '@/stores/keime/UpdateKeimRequest'
import { v4 as uuidv4 } from 'uuid'

export const useKeimeStore = defineStore('keime', () => {
  const stammdatenApi = useStammdatenApi()

  const keimeState = ref<Keim[]>([])
  const isLoadingState = ref(false)
  const selectedItemId = ref<string>()

  const fetchKeime = async () => {
    const req = stammdatenApi('Keime').get().json()
    const { data, isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()

    if (error.value) return
    keimeState.value = data.value as Keim[]
  }

  const keimeCount = computed(() => keimeState.value.length)

  const addKeim = async ({ kuerzel, name }: { kuerzel: string; name: string }) => {
    const temporaryGuid = uuidv4()
    const newKeim = {
      id: temporaryGuid,
      name: name,
      kuerzel: kuerzel,
      updateDateTime: undefined
    } as Keim

    keimeState.value.push(newKeim)
    const req = stammdatenApi<Keim>('Keime/', {})
      .post({
        kuerzel,
        name
      })
      .json()

    const { data, error } = req
    await req
    if (error.value || !data.value) {
      keimeState.value = keimeState.value.filter((x) => x.id !== temporaryGuid)
    }
    const k = keimeState.value.find((x) => x.id == temporaryGuid)
    if (k) k.id = data.value.id
    selectedItemId.value = data.value.id
  }

  const deleteKeim = async (id: string) => {
    const keimIndex = keimeState.value.findIndex((k) => k.id === id)
    const keimToDelete = keimeState.value.at(keimIndex)
    if (keimToDelete) keimeState.value.splice(keimIndex, 1)

    const req = stammdatenApi(`Keime/${id}`).delete()
    const { error } = req

    if (selectedItemId.value === id) selectedItemId.value = undefined
    await req
    if (error.value) {
      keimeState.value.splice(keimIndex, 0, keimToDelete)
    }
  }

  const updateKeim = async (changes: PartialWithRequiredFields<Keim, 'id'>) => {
    const oldItem = keimeState.value.find((i) => i.id === changes.id)
    if (!oldItem) return
    const newItem = {
      id: changes.id,
      kuerzel: changes?.kuerzel ?? oldItem?.kuerzel,
      name: changes?.name ?? oldItem?.name
    }

    const preparedItem: UpdateKeimRequest = {
      id: newItem?.id,
      kuerzel: newItem?.kuerzel,
      name: newItem?.name
    }
    const existsInState = updateDataInKeimById(keimeState, newItem)

    const req = stammdatenApi<Keim>(`Keime`).put(preparedItem)
    const { isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isLoadingState.value = v))
    await req
    unwatch()

    if (error.value) {
      if (existsInState) updateDataInKeimById(keimeState, oldItem)
      return
    }

    await fetchKeime()
  }

  const updateDataInKeimById = (keime: Ref<Keim[]>, data: Keim) => {
    const index = keime.value.findIndex((r) => r.id === data.id)
    if (index === -1) {
      return false
    } else {
      keime.value[index] = data
      return true
    }
  }

  const isLoading = computed(() => isLoadingState.value && keimeState.value.length === 0)

  return {
    isLoading: readonly(isLoading),
    keime: readonly(keimeState),
    fetchKeime,
    createKeim: addKeim,
    deleteKeim,
    updateKeim,
    keimeCount
  }
})
