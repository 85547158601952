import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import { computed, readonly, ref, watch } from 'vue'
import type { RegelgruppenMetaResponse } from '@/stores/regelgruppen/RegelgruppenMetaResponse'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import { usePatientenProfileStore } from '@/stores/patientenprofile/PatientenprofileStore'
import type { PartialWithRequiredFields } from '@/models/utils'
import type { UpdateRegelRequest } from '@/stores/regeln/models/UpdateRegelRequest'
import type { RegelCopyRequest } from '@/stores/regelgruppen/RegelCopyRequest'
import type { ProbenGueter } from '@/stores/regeln/models/ProbenGueter'
import { useProbengueterStore } from '@/stores/probengüter/ProbengueterStore'
import type { EmptyRegelposition, RegelResponse } from '@/stores/regeln/models/RegelpositionResponse'
import {
  getIndexByZielStatus,
  getRegelPostionsTypeIndex,
  getZielStatusByIndex,
  type Regelposition,
  RegelPostionsType,
  ZielStatus
} from '@/stores/regeln/models/Regelposition'
import type { Regel } from '@/stores/regeln/models/regel'
import type {
  MibiRegelposition,
  QuantitativeRegelposition,
  UpdateRegelpositionRequest
} from '@/stores/regeln/models/UpdateRegelpositionRequest'
import { replaceItemAt } from '@/utils'
import { t } from '@/i18n'
import { useRegelgruppenStore } from '@/stores/regelgruppen/RegelgruppenStore'
import { v4 as uuidv4 } from 'uuid'
import type { Gueltigkeit } from '@/stores/regeln/models/Gueltigkeit'
import { RegelSubItemType } from '@/stores/regeln/models/RegelSubItemType'
import type { BefundtextInstanz } from '@/stores/regeln/models/BefundtextInstanz'

export const useRegelnStore = defineStore('regeln', () => {
  const regelnState = useSessionStorage<Regel[]>('regeln', [])
  const patientenProfilStore = usePatientenProfileStore()
  const regelgruppenStore = useRegelgruppenStore()
  const probengueterStore = useProbengueterStore()
  const selectedItemId = ref<string>()
  const isLoading = ref(false)
  const isDetailsLoading = ref(false)
  const stammdatenApi = useStammdatenApi()

  const updateStore = (regel: Regel) => {
    const index = regelnState.value.findIndex((r) => r.id === regel.id)
    if (index === -1) {
      return false
    } else {
      regelnState.value[index] = regel
      return true
    }
  }

  const addRegel = async ({ name }: { name: string }) => {
    if (!patientenProfilStore.selectedItem) return
    const position = (patientenProfilStore.selectedItem?.regeln.length ?? 0) + 1

    const temporaryGuid = uuidv4()

    const newRegel = {
      id: temporaryGuid,
      position: position,
      name: name,
      befundText: {} as BefundtextInstanz,
      gueltigkeiten: {} as Gueltigkeit,
      probengueter: [],
      regelpositionen: [],
      isProbengutAdditive: true,
      isLocked: false
    } as Regel

    patientenProfilStore.addRegelFromPatientenProfil(newRegel, patientenProfilStore.selectedItem.id)

    const req = stammdatenApi<RegelgruppenMetaResponse>('Regelgruppen/Regel', {})
      .post({
        name,
        position,
        patientenProfilId: patientenProfilStore.selectedItem?.id
      })
      .json()
    const { isFetching, data, error } = req
    const unwatch = watch(isFetching, (v) => (isLoading.value = v))
    await req
    unwatch()
    if (error.value || !data.value) {
      await patientenProfilStore.updatePatientenProfil({
        id: patientenProfilStore.selectedItem.id,
        regeln: [
          ...patientenProfilStore.selectedItem!.regeln.filter((x) => x.id !== temporaryGuid)
        ] as Regel[]
      })
      return
    }
    const regel = mapRegelResponse(data.value)
    patientenProfilStore.updateRegelFromPatientenProfil(
      regel,
      patientenProfilStore.selectedItem.id,
      temporaryGuid
    )
    regelnState.value.push(regel)
    selectRegel(regel.id)
  }

  const updateRegel = async (changes: PartialWithRequiredFields<Regel, 'id'>) => {
    const oldItem = regelnState.value.find((x) => x.id == changes.id)
    if (!oldItem) return
    const newItem = { ...oldItem, ...changes }
    const updateRequest: UpdateRegelRequest = {
      id: newItem.id,
      name: newItem.name,
      position: newItem.position,
      isProbengutAdditive: newItem.isProbengutAdditive,
      probengueter: newItem.probengueter,
      gueltigkeiten: newItem.gueltigkeiten,
      befundtext: newItem.befundText
    }

    updateStore(newItem)
    patientenProfilStore.updateRegelFromPatientenProfil(
      newItem,
      patientenProfilStore.selectedItem?.id ?? ''
    )

    selectRegel(changes.id)
    const req = stammdatenApi<Regel>(`Regelgruppen/Regel`).put(updateRequest).json<RegelResponse>()

    const { data, error } = req
    await req
    if (error.value || !data.value) {
      updateStore(oldItem)
      patientenProfilStore.updateRegelFromPatientenProfil(
        oldItem,
        patientenProfilStore.selectedItem?.id ?? ''
      )
      return
    }

    const regel = mapRegelResponse(data.value)
    patientenProfilStore.updateRegelFromPatientenProfil(
      regel,
      patientenProfilStore.selectedItem?.id ?? ''
    )
    updateStore(regel)
  }

  const reorderRegeln = async (id: string, position: number) => {
    let regeln = patientenProfilStore.selectedItem?.regeln
    if (!regeln) return
    const oldPosition = regeln.find((x) => x.id == id)
    if (!oldPosition) return
    if (oldPosition.position > position) {
      regeln
        .filter((x) => x.position >= position && x.position < oldPosition.position)
        .map((x) => x.position++)
    } else if (oldPosition.position < position) {
      regeln
        .filter((x) => x.position <= position && x.position > oldPosition.position)
        .forEach((x) => x.position--)
    }
    oldPosition.position = position
    regeln = regeln.sort((a, b) => a.position - b.position)

    const req = stammdatenApi<Regel>(
      `Regelgruppen/Regel/Reorder`,
      { headers: new Headers({ 'content-type': 'application/json' }) },
      {}
    ).put(JSON.stringify({ RegelId: id, NewPosition: position }))

    const { error } = req
    await req
    if (error.value) {
      console.log(error.value)
      await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
      return
    }
  }

  const deleteRegel = async (id: string) => {
    const regelIndex = regelnState.value.findIndex((r) => r.id === id)
    const regelToDelete = regelnState.value.at(regelIndex)
    if (regelToDelete) {
      regelnState.value.splice(regelIndex, 1)
      regelnState.value
        .filter(
          (x) =>
            patientenProfilStore.selectedItem?.regeln.map((r) => r.id).includes(x.id) &&
            x.position > regelToDelete.position
        )
        .map((x) => x.position--)
      patientenProfilStore.deleteRegelFromPatientenProfil(id)
      if (id === selectedItemId.value) selectedItemId.value = undefined
    }

    const req = stammdatenApi(`Regelgruppen/Regel/${id}`).delete()
    const { error } = req

    if (selectedItemId.value === id) selectedItemId.value = undefined
    await req
    if (error.value) {
      if (regelToDelete) {
        regelnState.value.splice(regelIndex, 0, regelToDelete)
        regelnState.value
          .filter(
            (x) =>
              patientenProfilStore.selectedItem?.regeln.map((r) => r.id).includes(x.id) &&
              x.position >= regelToDelete.position
          )
          .map((x) => x.position++)
        patientenProfilStore.selectedItem!.regeln.push(regelToDelete)
      }
    }
  }

  const copyRegel = async (id: string, name: string) => {
    const request: RegelCopyRequest = { name: name }
    const oldRegel = patientenProfilStore.selectedItem?.regeln.find((x) => x.id == id)
    if (!oldRegel) return
    const temporaryGuid = uuidv4()
    const newRegel = { ...oldRegel }
    newRegel.id = temporaryGuid
    newRegel.name = name
    newRegel.position = patientenProfilStore.selectedItem?.regeln.length
      ? patientenProfilStore.selectedItem?.regeln.length + 1
      : 0
    patientenProfilStore.selectedItem?.regeln.push(newRegel)

    const req = stammdatenApi<Regel>(`Regelgruppen/Regel/Copy/${id}`).post(request).json()
    const { data, error } = req
    await req

    if (!error.value && data.value) {
      const res = patientenProfilStore.selectedItem?.regeln.find((x) => x.id == temporaryGuid)
      if (res) res.id = data.value.id
    }
    await patientenProfilStore.selectPatientenProfile(patientenProfilStore.selectedItem?.id)
  }

  const handleNewRegelnFromPatientenProfil = (regeln: RegelResponse[]) => {
    const selectedId = selectedItemId.value
    regeln.map(mapRegelResponse).forEach((r) => {
      const existsInState = updateStore(r)
      if (!existsInState) {
        regelnState.value.push(r)
      }
    })
    if (selectedId) {
      const oldSelected = patientenProfilStore.selectedItem?.regeln.find((x) => x.id == selectedId)
      if (oldSelected) selectRegel(selectedId)
      else {
        selectRegel(undefined)
      }
    }
  }

  const mapRegelResponse = (regel: RegelResponse): Regel => {
    const regelpositionen: Regelposition[] = []

    const createEmptyRp = (rp: EmptyRegelposition) => {
      if (rp.analyse) return createEmptyRpForAnalyse(rp)
      if (rp.keim) return createEmptyRpForKeim(rp)
      if (rp.keimgruppe) return createEmptyRpForKeimgruppe(rp)
      throw Error('rp is not valid')
    }

    const createEmptyRpForAnalyse = (rp: EmptyRegelposition) => ({
      subItemId: rp.analyse!.id,
      subItemType: RegelSubItemType.Analyse,
      kuerzel: rp.analyse!.kuerzel,
      zielstatus: getZielStatusByIndex(rp.zielStatus) ?? ZielStatus.E,
      id: rp.id
    })
    const createEmptyRpForKeim = (rp: EmptyRegelposition) => ({
      subItemId: rp.keim!.id,
      subItemType: RegelSubItemType.Keim,
      kuerzel: rp.keim!.kuerzel,
      zielstatus: getZielStatusByIndex(rp.zielStatus) ?? ZielStatus.E,
      id: rp.id
    })

    const createEmptyRpForKeimgruppe = (rp: EmptyRegelposition) => ({
      subItemId: rp.keimgruppe!.id,
      subItemType: RegelSubItemType.Keimgruppe,
      kuerzel: rp.keimgruppe!.kuerzel,
      zielstatus: getZielStatusByIndex(rp.zielStatus) ?? ZielStatus.E,
      id: rp.id
    })

    const emptyRegelPositionen =
      regel.regelpositionen.emptyRegelposition.map<Regelposition>(createEmptyRp)

    const enthalteneRegelpositionen =
      regel.regelpositionen.enthalteneRegelposition.map<Regelposition>((rp) => ({
        ...createEmptyRp(rp),
        regelSpezifikation: {
          type: 'ENTHALTEN',
          value: rp.isEnthalten ? t('dialogs.IstEnthalten') : t('dialogs.NichtEnthalten')
        }
      }))

    const qualitativeRegelPositionen =
      regel.regelpositionen.qualitativeRegelpositionen.map<Regelposition>((rp) => ({
        ...createEmptyRp(rp),
        regelSpezifikation: {
          type: 'QUALITATIV',
          value: rp.targetValue,
          deltaCheckValues: rp.deltaCheckValues
        }
      }))

    const quantitativeRegelPositionen =
      regel.regelpositionen.quantitativeRegelposition.map<Regelposition>((rp) => ({
        ...createEmptyRp(rp),
        regelSpezifikation: {
          type: 'QUANTITATIV',
          value: {
            operand1: rp.operand1,
            wert1: rp.wert1,
            deltaCheck: rp.deltaCheck,
            operand2: rp.operand2,
            wert2: rp.wert2
          }
        }
      }))

    const mibiRegelPositionen = regel.regelpositionen.mibiRegelposition.map<Regelposition>(
      (rp) => ({
        ...(rp.keim ? createEmptyRpForKeim(rp) : createEmptyRpForKeimgruppe(rp)),
        regelSpezifikation: {
          type: RegelPostionsType.MIBI,
          value: {
            mreStufe: rp.mreStufe,
            resistenzMusterId: rp.resistenzmusterId,
            validationType: rp.validationType
          }
        }
      })
    )

    regelpositionen.push(
      ...emptyRegelPositionen,
      ...enthalteneRegelpositionen,
      ...qualitativeRegelPositionen,
      ...quantitativeRegelPositionen,
      ...mibiRegelPositionen
    )
    regelpositionen.sort((a, b) => a.kuerzel.localeCompare(b.kuerzel))

    return { ...regel, regelpositionen }
  }

  const updateRegelposition = async (rp: Regelposition) => {
    if (selectedItemId.value === undefined) return
    const oldRegelposIndex = selectedItem.value!.regelpositionen.findIndex((r) => r.id === rp.id)
    if (oldRegelposIndex === -1) return
    const oldRegelpos = selectedItem.value!.regelpositionen[oldRegelposIndex]
    selectedItem.value!.regelpositionen = replaceItemAt(
      selectedItem.value!.regelpositionen,
      rp,
      oldRegelposIndex
    )

    const payload: UpdateRegelpositionRequest = {
      id: rp.id,
      regelId: selectedItemId.value,
      subItemId: rp.subItemId,
      subItemType: rp.subItemType,
      zielStatus: getIndexByZielStatus(rp.zielstatus),
      regelPositionType: getRegelPostionsTypeIndex(
        rp.regelSpezifikation?.type ?? RegelPostionsType.EMPTY
      ),
      enthaltenRegelposition:
        rp.regelSpezifikation?.type === RegelPostionsType.ENTHALTEN
          ? {
            isEnthalten: rp.regelSpezifikation.value === t('dialogs.IstEnthalten')
          }
          : undefined,
      qualitativeRegelposition:
        rp.regelSpezifikation?.type === RegelPostionsType.QUALITATIV
          ? {
            targetValue: rp.regelSpezifikation.value!,
            deltaCheckValues: rp.regelSpezifikation.deltaCheckValues
          }
          : undefined,
      quantitativeRegelposition:
        rp.regelSpezifikation?.type === RegelPostionsType.QUANTITATIV
          ? (rp.regelSpezifikation.value as QuantitativeRegelposition)
          : undefined,
      mibiRegelposition:
        rp.regelSpezifikation?.type === RegelPostionsType.MIBI
          ? (rp.regelSpezifikation.value as MibiRegelposition)
          : undefined
    }

    const { error, data } = await stammdatenApi<Regel>(`Regelgruppen/Regel/Regelposition`)
      .post(payload)
      .json<RegelResponse>()
    if (error.value || !data.value) {
      selectedItem.value!.regelpositionen = replaceItemAt(
        selectedItem.value!.regelpositionen,
        oldRegelpos,
        oldRegelposIndex
      )
      return
    }
    const mappedRp = mapRegelResponse(data.value)
    regelgruppenStore.handleRegelpositionUpdated(rp)
    updateStore(mappedRp)
  }

  const selectRegel = (id: string | undefined) => {
    selectedItemId.value = id
  }

  const addProbengutToSelectedItem = async (probengutMeta: ProbenGueter) => {
    if (!selectedItem.value) return
    const probengut = probengueterStore.probengueter.find(
      (p) => p.kuerzel === probengutMeta.kuerzel
    )
    if (!probengut) return

    const req = stammdatenApi('Regelgruppen/Regel/Probengut')
      .post({
        regelId: selectedItem.value.id,
        probengutId: probengut.id
      })
      .json()
    const { data, isFetching, error } = req
    const unwatch = watch(isFetching, (v) => (isDetailsLoading.value = v))
    await req
    unwatch()
    if (error.value) return

    const updatedRegel = { ...selectedItem.value }
    updatedRegel.probengueter = data.value.probengueter

    const i = regelnState.value.findIndex((x) => x.id == selectedItem.value?.id)

    regelnState.value[i] = updatedRegel
    patientenProfilStore.updateRegelFromPatientenProfil(
      updatedRegel,
      patientenProfilStore.selectedItem?.id ?? ''
    )
  }

  const deleteProbengutFromSelectedItem = async (probengutId: string) => {

    if (!selectedItem.value) return

    const req = stammdatenApi('Regelgruppen/Regel/Probengut')
      .delete({
        regelId: selectedItem.value.id,
        probengutId: probengutId
      })
      .json()
    const { data, isFetching, error } = req
    console.log(data)

    const unwatch = watch(isFetching, (v) => (isDetailsLoading.value = v))
    await req
    unwatch()
    if (error.value) return

    const updatedRegel = { ...selectedItem.value }
    updatedRegel.probengueter = data.value.probengueter

    const i = regelnState.value.findIndex((x) => x.id == selectedItem.value?.id)

    regelnState.value[i] = updatedRegel
    patientenProfilStore.updateRegelFromPatientenProfil(
      updatedRegel,
      patientenProfilStore.selectedItem?.id ?? ''
    )
  }

  const selectedItem = computed(() => regelnState.value.find((r) => r.id === selectedItemId.value))

  return {
    isLoading: readonly(isLoading),
    regeln: readonly(regelnState),
    selectedItem,
    selectRegel: selectRegel,
    handleNewRegelnFromPatientenProfil,
    addRegel,
    updateRegel,
    deleteRegel,
    reorderRegeln,
    copyRegel,
    updateRegelposition,
    addProbengut: addProbengutToSelectedItem,
    deleteProbengut: deleteProbengutFromSelectedItem
  }
})
