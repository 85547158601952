export const LanguagesWithCode = [
  {
    'code': 'af',
    'name': 'Afghanistan',
    'language': 'Paschtu'
  },
  {
    'code': 'ax',
    'name': 'Aland Islands',
    'language': 'Schwedisch'
  },
  {
    'code': 'al',
    'name': 'Albania',
    'language': 'Albanisch'
  },
  {
    'code': 'dz',
    'name': 'Algeria',
    'language': 'Arabisch'
  },
  {
    'code': 'as',
    'name': 'American Samoa',
    'language': 'Englisch'
  },
  {
    'code': 'ad',
    'name': 'Andorra',
    'language': 'Katalanisch'
  },
  {
    'code': 'ao',
    'name': 'Angola',
    'language': 'Portugiesisch'
  },
  {
    'code': 'ai',
    'name': 'Anguilla',
    'language': 'Englisch'
  },
  {
    'code': 'aq',
    'name': 'Antarctica',
    'language': 'Keine Amtssprache'
  },
  {
    'code': 'ag',
    'name': 'Antigua and Barbuda',
    'language': 'Englisch'
  },
  {
    'code': 'ar',
    'name': 'Argentina',
    'language': 'Spanisch'
  },
  {
    'code': 'am',
    'name': 'Armenia',
    'language': 'Armenisch'
  },
  {
    'code': 'aw',
    'name': 'Aruba',
    'language': 'Niederländisch'
  },
  {
    'code': 'sh-ac',
    'name': 'Ascension Island',
    'language': 'Englisch'
  },
  {
    'code': 'au',
    'name': 'Australia',
    'language': 'Englisch'
  },
  {
    'code': 'at',
    'name': 'Austria',
    'language': 'Deutsch'
  },
  {
    'code': 'az',
    'name': 'Azerbaijan',
    'language': 'Aserbaidschanisch'
  },
  {
    'code': 'bs',
    'name': 'Bahamas',
    'language': 'Englisch'
  },
  {
    'code': 'bh',
    'name': 'Bahrain',
    'language': 'Arabisch'
  },
  {
    'code': 'bd',
    'name': 'Bangladesh',
    'language': 'Bengalisch'
  },
  {
    'code': 'bb',
    'name': 'Barbados',
    'language': 'Englisch'
  },
  {
    'code': 'by',
    'name': 'Belarus',
    'language': 'Weißrussisch'
  },
  {
    'code': 'be',
    'name': 'Belgium',
    'language': 'Französisch'
  },
  {
    'code': 'bz',
    'name': 'Belize',
    'language': 'Englisch'
  },
  {
    'code': 'bj',
    'name': 'Benin',
    'language': 'Französisch'
  },
  {
    'code': 'bm',
    'name': 'Bermuda',
    'language': 'Englisch'
  },
  {
    'code': 'bt',
    'name': 'Bhutan',
    'language': 'Dzongkha'
  },
  {
    'code': 'bo',
    'name': 'Bolivia',
    'language': 'Spanisch'
  },
  {
    'code': 'ba',
    'name': 'Bosnia and Herzegovina',
    'language': 'Bosnisch'
  },
  {
    'code': 'bw',
    'name': 'Botswana',
    'language': 'Englisch'
  },
  {
    'code': 'br',
    'name': 'Brazil',
    'language': 'Portugiesisch'
  },
  {
    'code': 'bg',
    'name': 'Bulgaria',
    'language': 'Bulgarisch'
  },
  {
    'code': 'ca',
    'name': 'Canada',
    'language': 'Englisch'
  },
  {
    'code': 'cn',
    'name': 'China',
    'language': 'Mandarin'
  },
  {
    'code': 'co',
    'name': 'Colombia',
    'language': 'Spanisch'
  },
  {
    'code': 'cr',
    'name': 'Costa Rica',
    'language': 'Spanisch'
  },
  {
    'code': 'hr',
    'name': 'Croatia',
    'language': 'Kroatisch'
  },
  {
    'code': 'cu',
    'name': 'Cuba',
    'language': 'Spanisch'
  },
  {
    'code': 'cy',
    'name': 'Cyprus',
    'language': 'Griechisch'
  },
  {
    'code': 'cz',
    'name': 'Czech Republic',
    'language': 'Tschechisch'
  },
  {
    'code': 'dk',
    'name': 'Denmark',
    'language': 'Dänisch'
  },
  {
    'code': 'eg',
    'name': 'Egypt',
    'language': 'Arabisch'
  },
  {
    'code': 'fi',
    'name': 'Finland',
    'language': 'Finnisch'
  },
  {
    'code': 'fr',
    'name': 'France',
    'language': 'Französisch'
  },
  {
    'code': 'de',
    'name': 'Germany',
    'language': 'Deutsch'
  },
  {
    'code': 'gr',
    'name': 'Greece',
    'language': 'Griechisch'
  },
  {
    'code': 'in',
    'name': 'India',
    'language': 'Hindi'
  },
  {
    'code': 'ie',
    'name': 'Ireland',
    'language': 'Englisch'
  },
  {
    'code': 'it',
    'name': 'Italy',
    'language': 'Italienisch'
  },
  {
    'code': 'jp',
    'name': 'Japan',
    'language': 'Japanisch'
  },
  {
    'code': 'ke',
    'name': 'Kenya',
    'language': 'Swahili'
  },
  {
    'code': 'mx',
    'name': 'Mexico',
    'language': 'Spanisch'
  },
  {
    'code': 'nl',
    'name': 'Netherlands',
    'language': 'Niederländisch'
  },
  {
    'code': 'nz',
    'name': 'New Zealand',
    'language': 'Englisch'
  },
  {
    'code': 'no',
    'name': 'Norway',
    'language': 'Norwegisch'
  },
  {
    'code': 'pl',
    'name': 'Poland',
    'language': 'Polnisch'
  },
  {
    'code': 'pt',
    'name': 'Portugal',
    'language': 'Portugiesisch'
  },
  {
    'code': 'ru',
    'name': 'Russia',
    'language': 'Russisch'
  },
  {
    'code': 'za',
    'name': 'South Africa',
    'language': 'Englisch'
  },
  {
    'code': 'es',
    'name': 'Spain',
    'language': 'Spanisch'
  },
  {
    'code': 'se',
    'name': 'Sweden',
    'language': 'Schwedisch'
  },
  {
    'code': 'ch',
    'name': 'Switzerland',
    'language': 'Deutsch'
  },
  {
    'code': 'gb',
    'name': 'United Kingdom',
    'language': 'Englisch'
  },
  {
    'code': 'us',
    'name': 'United States of America',
    'language': 'Englisch'
  },
  {
    'code': 'vn',
    'name': 'Vietnam',
    'language': 'Vietnamesisch'
  },
  {
    'code': 'zw',
    'name': 'Zimbabwe',
    'language': 'Englisch'
  }
]

