import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useStammdatenApi } from '@/composables/stammdatenApiComposable'
import type { Settings } from '@/stores/Settings/Settings'

export const useSettingsStore = defineStore('settings', () => {
  const stammdatenApi = useStammdatenApi()

  const settingsState = ref<Settings>({ activeLanguages: ['gb', 'de', 'fr'], allLanguages: [] })

  const fetchSettings = async () => {
    const req = stammdatenApi('Settings').get().json()
    const { data, error } = req
    await req
    if (error.value) return
    settingsState.value = data.value as Settings
  }

  const updateLanguages = async (languages: string[]) => {
    try {
      const req = await stammdatenApi('Settings').put({ languages: languages }).json()
      const { error } = req
      if (error?.value) return
      settingsState.value.activeLanguages = languages
    } catch (e) {
      console.error('Failed to update languages:', e)
    }
  }
  return {
    fetchSettings,
    settingsState,
    updateLanguages
  }
})
