<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { KeycloakResourceAccess, KeycloakTokenParsed } from 'keycloak-js'
import { keycloak } from '@/composables/stammdatenApiComposable'
import { useEnv } from '@/composables/enviromentComposable'
import Settings from '@/components/Settings/Settings.vue'

const { t } = useI18n()
const env = useEnv()

const menu = ref()
const isSettingsDialogVisible = ref<boolean>(false)
const settingsTab = ref<'Profile' | 'Settings'>('Profile')
const toggle = (event) => {
  menu.value.toggle(event)
}
const firstName = ref<string>('')
const lastName = ref<string>('')
const email = ref<string>('')
const isUserManager = ref<boolean>(false)
const showDialog = ref(false)
onMounted(() => {
  const userInfo: KeycloakTokenParsed | undefined = keycloak.tokenParsed
  firstName.value = userInfo?.given_name
  lastName.value = userInfo?.family_name
  email.value = userInfo?.email
  isUserManager.value = (userInfo?.resource_access as KeycloakResourceAccess)['realm-management']
    ? (userInfo?.resource_access as KeycloakResourceAccess)['realm-management']['roles']?.includes(
        'manage-users'
      )
    : false
})

const items = computed(() => [
  {
    separator: true
  },

  {
    label: t('menu.Profile'),
    items: [
      {
        label: t('menu.Settings'),
        icon: 'fa-solid fa-sliders',
        action: () => {
          isSettingsDialogVisible.value = true
          settingsTab.value = 'Settings'
        }
      },
      {
        label: t('menu.Profile'),
        icon: 'fa-solid fa-user-pen',
        action: () => {
          const url = `${env.KEYCLOAK_REALM_URI}/account/` // Hier die URL, die du öffnen möchtest
          window.open(url, '_blank') // _blank öffnet die URL in einem neuen Tab
        }
      },
      isUserManager.value && {
        label: t('menu.ManageUsers'),
        icon: 'fa-solid fa-users',
        action: () => {
          const url = `${env.KEYCLOAK_ADMIN_REALM_URI}/console/` // Hier die URL, die du öffnen möchtest
          window.open(url, '_blank') // _blank öffnet die URL in einem neuen Tab
        }
      },
      {
        label: t('menu.Logout'),
        icon: 'fa-solid fa-arrow-right-from-bracket',
        action: () => keycloak.logout()
      }
    ].filter(Boolean)
  },
  {
    separator: true
  }
])
</script>

<template>
  <Avatar
    icon="fa-solid fa-user"
    shape="circle"
    style="cursor: pointer"
    @click="toggle"
  />

  <Menu
    ref="menu"
    :model="items"
    :popup="true"
    class="w-full md:w-60"
  >
    <template #start>
      <div style="width: 100%; display: flex; justify-content: center">
        <img
          :height="15"
          alt="Limbach"
          src="@/assets/Limbach.png"
          style="width: 200px; padding: 1rem"
        >
      </div>
    </template>
    <template #submenulabel="{ item }">
      <span class="text-primary font-bold">{{ item.label }}</span>
    </template>
    <template #item="{ item, props }">
      <a
        class="flex items-center"
        v-bind="props.action"
        @click="item.action"
      >
        <span
          :class="item.icon"
          style="width: 1.3rem"
        />
        <span>{{ item.label }}</span>
        <Badge
          v-if="item.badge"
          :value="item.badge"
          class="ml-auto"
        />
        <span
          v-if="item.shortcut"
          class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1"
        >{{ item.shortcut }}</span>
      </a>
    </template>
    <template #end>
      <button
        class="relative overflow-hidden w-full border-0 bg-transparent flex items-start p-2 pl-4 rounded-none"
      >
        <span class="inline-flex flex-col items-start">
          <span class="font-bold">{{ firstName }} {{ lastName }}</span>
          <span class="text-sm">{{ email }}</span>
        </span>
      </button>
    </template>
  </Menu>

  <Settings v-model:isVisible="isSettingsDialogVisible" />

  <Dialog
    :style="{ width: '800px' }"
    :visible="showDialog"
    modal
    @hide="showDialog = false"
  >
    <template #header>
      <h3>Externe Website</h3>
    </template>

    <iframe
      frameborder="0"
      height="500px"
      :src="`${env.KEYCLOAK_REALM_URI}/account/`"
      width="100%"
    />

    <template #footer>
      <Button
        icon="pi pi-times"
        label="Schließen"
        @click="showDialog = false"
      />
    </template>
  </Dialog>
</template>

<style lang="scss" scoped></style>
